/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Admin Portal - API
 * # Introduction    This API provides access to admin and customer management.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Forbidden,
  MeterInstallationRequestModel,
  MeterModel,
  MeterResponseModel,
  Unauthorized,
} from '../models';

export interface CrmInstallationsMeterAddMeterDeviceRequest {
    id: string;
    meterInstallationRequestModel?: MeterInstallationRequestModel;
}

export interface CrmInstallationsMeterDeleteMeterDeviceRequest {
    id: string;
    configurationId: string;
}

export interface CrmInstallationsMeterGetMeterDeviceRequest {
    id: string;
    deviceId: string;
}

export interface CrmInstallationsMeterGetMeterDevicesRequest {
    id: string;
    deviceCategory?: string;
}

export interface CrmInstallationsMeterUpdateMeterDeviceRequest {
    id: string;
    configurationId: string;
    meterInstallationRequestModel?: MeterInstallationRequestModel;
}

/**
 * 
 */
export class CRMInstallationsMeterApi extends runtime.BaseAPI {

    /**
     * Add a Smart Meter configuration
     */
    async crmInstallationsMeterAddMeterDeviceRaw(requestParameters: CrmInstallationsMeterAddMeterDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MeterModel>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmInstallationsMeterAddMeterDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/installations/meter`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.meterInstallationRequestModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Add a Smart Meter configuration
     */
    async crmInstallationsMeterAddMeterDevice(requestParameters: CrmInstallationsMeterAddMeterDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MeterModel>> {
        const response = await this.crmInstallationsMeterAddMeterDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove a Smart Meter configuration
     */
    async crmInstallationsMeterDeleteMeterDeviceRaw(requestParameters: CrmInstallationsMeterDeleteMeterDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmInstallationsMeterDeleteMeterDevice.');
        }

        if (requestParameters.configurationId === null || requestParameters.configurationId === undefined) {
            throw new runtime.RequiredError('configurationId','Required parameter requestParameters.configurationId was null or undefined when calling crmInstallationsMeterDeleteMeterDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/installations/meter/{configurationId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"configurationId"}}`, encodeURIComponent(String(requestParameters.configurationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a Smart Meter configuration
     */
    async crmInstallationsMeterDeleteMeterDevice(requestParameters: CrmInstallationsMeterDeleteMeterDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.crmInstallationsMeterDeleteMeterDeviceRaw(requestParameters, initOverrides);
    }

    /**
     * Find a Smart Meter configuration
     */
    async crmInstallationsMeterGetMeterDeviceRaw(requestParameters: CrmInstallationsMeterGetMeterDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeterResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmInstallationsMeterGetMeterDevice.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling crmInstallationsMeterGetMeterDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/installations/meter/{deviceId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Find a Smart Meter configuration
     */
    async crmInstallationsMeterGetMeterDevice(requestParameters: CrmInstallationsMeterGetMeterDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeterResponseModel> {
        const response = await this.crmInstallationsMeterGetMeterDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all Smart Meter installations
     */
    async crmInstallationsMeterGetMeterDevicesRaw(requestParameters: CrmInstallationsMeterGetMeterDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MeterResponseModel>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmInstallationsMeterGetMeterDevices.');
        }

        const queryParameters: any = {};

        if (requestParameters.deviceCategory !== undefined) {
            queryParameters['device-category'] = requestParameters.deviceCategory;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/installations/meter`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get all Smart Meter installations
     */
    async crmInstallationsMeterGetMeterDevices(requestParameters: CrmInstallationsMeterGetMeterDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MeterResponseModel>> {
        const response = await this.crmInstallationsMeterGetMeterDevicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a Smart Meter configuration
     */
    async crmInstallationsMeterUpdateMeterDeviceRaw(requestParameters: CrmInstallationsMeterUpdateMeterDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeterModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmInstallationsMeterUpdateMeterDevice.');
        }

        if (requestParameters.configurationId === null || requestParameters.configurationId === undefined) {
            throw new runtime.RequiredError('configurationId','Required parameter requestParameters.configurationId was null or undefined when calling crmInstallationsMeterUpdateMeterDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/installations/meter/{configurationId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"configurationId"}}`, encodeURIComponent(String(requestParameters.configurationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.meterInstallationRequestModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Update a Smart Meter configuration
     */
    async crmInstallationsMeterUpdateMeterDevice(requestParameters: CrmInstallationsMeterUpdateMeterDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeterModel> {
        const response = await this.crmInstallationsMeterUpdateMeterDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
