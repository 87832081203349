/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Admin Portal - API
 * # Introduction    This API provides access to admin and customer management.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Forbidden,
  InstallationRequestModel,
  InstallationUpdateModel,
  PVBatteryModel,
  PVBatteryResponseModel,
  Unauthorized,
} from '../models';

export interface CrmInstallationsPvBatteryAddSmartEnergyDeviceRequest {
    id: string;
    installationRequestModel?: InstallationRequestModel;
}

export interface CrmInstallationsPvBatteryDeleteSmartEnergyDeviceRequest {
    id: string;
    configurationId: string;
}

export interface CrmInstallationsPvBatteryGetSmartEnergyDeviceRequest {
    id: string;
    installationId: string;
}

export interface CrmInstallationsPvBatteryGetSmartEnergyDevicesRequest {
    id: string;
    useCache?: string;
}

export interface CrmInstallationsPvBatteryLookupDevicesRequest {
    id: string;
    serialnumber?: string;
    vendorName?: string;
}

export interface CrmInstallationsPvBatteryUpdateSmartEnergyDeviceRequest {
    id: string;
    configurationId: string;
    installationUpdateModel?: InstallationUpdateModel;
}

/**
 * 
 */
export class CRMInstallationsPVBatteryApi extends runtime.BaseAPI {

    /**
     * Add a PV & Battery installation
     */
    async crmInstallationsPvBatteryAddSmartEnergyDeviceRaw(requestParameters: CrmInstallationsPvBatteryAddSmartEnergyDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PVBatteryResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmInstallationsPvBatteryAddSmartEnergyDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/installations/pvbattery`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.installationRequestModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Add a PV & Battery installation
     */
    async crmInstallationsPvBatteryAddSmartEnergyDevice(requestParameters: CrmInstallationsPvBatteryAddSmartEnergyDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PVBatteryResponseModel> {
        const response = await this.crmInstallationsPvBatteryAddSmartEnergyDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove a PV & Battery configuration
     */
    async crmInstallationsPvBatteryDeleteSmartEnergyDeviceRaw(requestParameters: CrmInstallationsPvBatteryDeleteSmartEnergyDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmInstallationsPvBatteryDeleteSmartEnergyDevice.');
        }

        if (requestParameters.configurationId === null || requestParameters.configurationId === undefined) {
            throw new runtime.RequiredError('configurationId','Required parameter requestParameters.configurationId was null or undefined when calling crmInstallationsPvBatteryDeleteSmartEnergyDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/installations/pvbattery/{configurationId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"configurationId"}}`, encodeURIComponent(String(requestParameters.configurationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a PV & Battery configuration
     */
    async crmInstallationsPvBatteryDeleteSmartEnergyDevice(requestParameters: CrmInstallationsPvBatteryDeleteSmartEnergyDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.crmInstallationsPvBatteryDeleteSmartEnergyDeviceRaw(requestParameters, initOverrides);
    }

    /**
     * Find a PV & Battery installation
     */
    async crmInstallationsPvBatteryGetSmartEnergyDeviceRaw(requestParameters: CrmInstallationsPvBatteryGetSmartEnergyDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PVBatteryModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmInstallationsPvBatteryGetSmartEnergyDevice.');
        }

        if (requestParameters.installationId === null || requestParameters.installationId === undefined) {
            throw new runtime.RequiredError('installationId','Required parameter requestParameters.installationId was null or undefined when calling crmInstallationsPvBatteryGetSmartEnergyDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/installations/pvbattery/{installationId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"installationId"}}`, encodeURIComponent(String(requestParameters.installationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Find a PV & Battery installation
     */
    async crmInstallationsPvBatteryGetSmartEnergyDevice(requestParameters: CrmInstallationsPvBatteryGetSmartEnergyDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PVBatteryModel> {
        const response = await this.crmInstallationsPvBatteryGetSmartEnergyDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all PV & Battery installations
     */
    async crmInstallationsPvBatteryGetSmartEnergyDevicesRaw(requestParameters: CrmInstallationsPvBatteryGetSmartEnergyDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PVBatteryModel>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmInstallationsPvBatteryGetSmartEnergyDevices.');
        }

        const queryParameters: any = {};

        if (requestParameters.useCache !== undefined) {
            queryParameters['useCache'] = requestParameters.useCache;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/installations/pvbattery`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get all PV & Battery installations
     */
    async crmInstallationsPvBatteryGetSmartEnergyDevices(requestParameters: CrmInstallationsPvBatteryGetSmartEnergyDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PVBatteryModel>> {
        const response = await this.crmInstallationsPvBatteryGetSmartEnergyDevicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lookup information about PV Battery by serial number
     */
    async crmInstallationsPvBatteryLookupDevicesRaw(requestParameters: CrmInstallationsPvBatteryLookupDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstallationRequestModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmInstallationsPvBatteryLookupDevices.');
        }

        const queryParameters: any = {};

        if (requestParameters.serialnumber !== undefined) {
            queryParameters['serialnumber'] = requestParameters.serialnumber;
        }

        if (requestParameters.vendorName !== undefined) {
            queryParameters['vendorName'] = requestParameters.vendorName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/installations/pvbattery/lookup`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Lookup information about PV Battery by serial number
     */
    async crmInstallationsPvBatteryLookupDevices(requestParameters: CrmInstallationsPvBatteryLookupDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstallationRequestModel> {
        const response = await this.crmInstallationsPvBatteryLookupDevicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a PV & Battery configuration
     */
    async crmInstallationsPvBatteryUpdateSmartEnergyDeviceRaw(requestParameters: CrmInstallationsPvBatteryUpdateSmartEnergyDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PVBatteryModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmInstallationsPvBatteryUpdateSmartEnergyDevice.');
        }

        if (requestParameters.configurationId === null || requestParameters.configurationId === undefined) {
            throw new runtime.RequiredError('configurationId','Required parameter requestParameters.configurationId was null or undefined when calling crmInstallationsPvBatteryUpdateSmartEnergyDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/installations/pvbattery/{configurationId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"configurationId"}}`, encodeURIComponent(String(requestParameters.configurationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.installationUpdateModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Update a PV & Battery configuration
     */
    async crmInstallationsPvBatteryUpdateSmartEnergyDevice(requestParameters: CrmInstallationsPvBatteryUpdateSmartEnergyDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PVBatteryModel> {
        const response = await this.crmInstallationsPvBatteryUpdateSmartEnergyDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
